<template>
  <div class="baloo_paaji_regular">
    <br>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="_font_2_5rem cor_red" data-aos="fade-down"><b>Perguntas Frequentes</b></h1>
          <h2 class="_font_1_5rem" data-aos="fade-up">Rapidez para seu cliente, eficiência para seu negócio.</h2>
          <br>
          <pro-divisor></pro-divisor>
        </v-col>
        <v-col cols="12">
          <v-expansion-panels data-aos="fade-up">
            <v-expansion-panel v-for="(item,i) in lista" :key="i">
              <v-expansion-panel-header><h2><b>{{item.pergunta}}</b></h2></v-expansion-panel-header>
              <v-expansion-panel-content style="text-align: justify">
                <span class="animarUp expansion_content">{{item.resposta}}</span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script async>
  /* eslint-disable */
  import miscMixin from '../../helpers/misc'

  export default {
    name: 'SecaoDuvidas',
    mixins: [miscMixin],
    data: () => ({
      lista: [
        {
          pergunta: 'Como faço para ter o meu Uaiped?',
          resposta: 'Basta entrar em contato pelo nosso WhatsApp e solicitar um teste de 30 dias.'
        },
        {
          pergunta: 'O Uaiped funciona para o meu negócio?',
          resposta: 'Sim, o Uaiped é ideal para todas as pessoas que trabalham com delivery e/ou pedidos sob demanda.'
        },
        {
          pergunta: 'Consigo personalizar o site com as cores da minha empresa?',
          resposta: 'Sim, você pode personalizar o seu cardápio com fotos, banners e logomarca com suas cores.'
        },
        {
          pergunta: 'Quando vou começar a pagar?',
          resposta: 'Você tem 30 dias de teste grátis e só depois vai começar a pagar pelo uso.'
        },
        {
          pergunta: 'Por que eu preciso do Uaiped no meu estabelecimento?',
          resposta: 'Economia de tempo na negociação com o seu cliente, seu próprio site de delivery online, envio de pedidos por WhatsApp.'
        },
        {
          pergunta: 'Trabalho com pedido sob demanda, o Uaiped funciona para mim ?',
          resposta: 'Funciona sim, nosso sistema permite que o seu cliente agende uma data e hora para entrega.'
        },
        {
          pergunta: 'Preciso de computador para gerenciar o painel de controle ?',
          resposta: 'Não, o painel de controle foi desenvolvido para que você possa acessa-lo em seu smartphone.'
        },
        {
          pergunta: 'Meus clientes precisam instalar algum aplicativo para fazer pedidos ?',
          resposta: 'Não, nosso sistema foi desenvolvido para que seu cliente não precise instalar nada no smartphone.'
        },
        // {
        //   pergunta: 'É possível personalizar fotos e as cores do meu Uaiped?',
        //   resposta: '
        // },
        // {
        //   pergunta: 'Posso adicionar banners personalizados com promoções, novidades em meu Uaiped?',
        //   resposta: ''
        // },
        {
          pergunta: 'Como funciona as formas de pagamento ?',
          resposta: 'O cliente irá informar a forma de pagamento que ele preferir no aplicativo, mas o pagamento será efetuado no ato da entrega.'
        },
        {
          pergunta: 'Como o Uaiped define o valor o frete?',
          resposta: 'O frente será definido de acordo o que for cadastrado para o bairro do cliente.'
        },
        // {
        //   pergunta: 'Tem a possibilidade de impressão do pedido direto do Zap?',
        //   resposta: ''
        // },
        {
          pergunta: 'Preciso ter um CNPJ para começar utilizar  Uaiped?',
          resposta: 'Não,  com seu CPF você também consegue utilizar todas as funcionalidades do Uaiped.'
        },
        // {
        //   pergunta: 'Posso cancelar quando eu quiser?',
        //   resposta: 'Sim, o cancelamento é instantâneo e pode ser feito a qualquer momento.'
        // },

        // {pergunta: '', resposta: ''},
      ]
    }),
    methods: {},
  }
</script>
